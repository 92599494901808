/* @import url("https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;1,300;1,400;1,500;1,600&family=Roboto+Condensed:wght@400;700&display=swap"); */
/* @import url("https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;1,300;1,400;1,500;1,600&family=Poppins:wght@300;400;600&family=Roboto+Condensed:wght@400;700&display=swap"); */
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600;700&family=Roboto+Condensed:wght@400;700&display=swap");

html,
body {
  font-size: 16px !important;
}
body {
  font-family: "Montserrat", sans-serif !important;
  /* font-family: "Open Sans", sans-serif !important; */
  /* font-family: "Poppins", sans-serif !important; */
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #2a3428;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Roboto Condensed", sans-serif !important;
}

.object-list-item {
  position: relative;
  overflow: hidden;
  cursor: pointer;
  border-radius: 5px;
  transition: all 300ms;
  /* box-shadow: 0px 0px 5px #00000038; */
  aspect-ratio: 3/2;
}

.object-list-item img {
  transition: all 300ms;
  width: 100%;
}

.object-list-item img:hover {
  transform: scale(1.05);
}

.object-list-item-rating {
  display: flex;
  position: absolute;
  gap: 0.2rem;
  top: 0;
  left: 0;
  padding: 0.3125rem 0.625rem;
}

.object-list-item-rating-star {
  text-shadow: 1px 1px 4px rgb(0 0 0);
  color: #ffffff;
  font-size: 0.875rem;
}

.object-list-item-distance {
  position: absolute;
  top: 0;
  right: 0;
  padding: 0.4375rem 0.625rem;
  color: white;
  font-size: 0.875rem;
  font-weight: 700;
  font-family: "Roboto Condensed", sans-serif;
  text-shadow: 1px 1px 4px rgb(0 0 0);
  min-width: 3.75rem;
  text-align: right;
}

.object-list-item-name {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 0.625rem;
  background: rgba(0, 0, 0, 0.5);
  color: white;
  font-size: 1.125rem;
  text-align: center;
  font-weight: bold;
  font-family: "Roboto Condensed", sans-serif;
}

.object-list {
  width: 100%;
  display: flex;
  flex-flow: wrap;
}

.group-list {
  display: block;
  white-space: nowrap;
  list-style: none;
  padding: 0;
  margin: 0;
}

.group-item {
  position: relative;
  white-space: normal;
  padding: 0.5rem;
  overflow: hidden;
  border-radius: 5px;
  background: rgb(1, 180, 188);
  background: linear-gradient(
    90deg,
    rgba(1, 180, 188, 1) 40%,
    rgba(1, 180, 188, 0.7) 100%
  );
  transition: all 300ms;
  min-height: 4.5625rem;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.group-item:hover {
}

.color-set-2 {
  background: rgb(188, 9, 1);
  background: linear-gradient(
    90deg,
    rgba(188, 9, 1, 1) 40%,
    rgba(188, 9, 1, 0.7) 100%
  );
}

.icon-2 {
  background: rgb(188, 9, 1);
}

.group-item-inner {
  display: flex;
  justify-content: space-between;
  cursor: pointer;
  gap: 0.5rem;
  align-self: center;
}

.group-item-inner-image {
  display: flex;
  max-width: 4.4375rem;
  width: -webkit-fill-available;
  min-height: 3.5rem;
}

.group-item-inner-image img {
  width: 80%;
  margin: 0 auto;
  filter: invert(1);
}

.group-item-inner-text {
  display: flex;
  flex-direction: column;
  align-self: center;
  overflow: hidden;
  width: -webkit-fill-available;
}

.group-item-inner-text-title {
  font-family: "Roboto Condensed", sans-serif;
  font-size: 1.25rem;
  line-height: 1.4375rem;
  font-weight: 700;
  color: #ffffff;
  text-transform: uppercase;
  padding-left: 1rem;
}

.group-item-inner-text-text {
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 1rem;
  color: #ffffff;
  white-space: nowrap;
  padding-left: 1rem;
}

.subgroup-list {
  white-space: nowrap;
  list-style: none;
  border-top: 1px solid white;
  margin-top: 0.5rem;
  margin-bottom: 0;
  padding: 0.5rem 0.5rem 0 0.5rem;
  width: 100%;
}

.subgroup-item {
  white-space: normal;
  display: block;
  padding: 0;
}

.subgroup-item a {
  display: block;
  color: #ffffff;
  text-decoration: none;
  padding: 0.125rem 0.3125rem;
  font-size: 0.9375rem;
  font-weight: 500;
}

.subgroup-item a:hover {
  display: block;
  color: #ffffff;
  background: rgba(255, 255, 255, 0.3);
  text-decoration: none;
  font-size: 0.9375rem;
  font-weight: 500;
}

.single-object-header {
  overflow: hidden;
  border-radius: 5px;
  aspect-ratio: 3/2;
}

.single-object-header img {
  width: 100%;
}

.white-bg {
  background: white;
}

.header-wrap {
  background: #ffffff;
  margin-bottom: 0.5rem;
  border-bottom: 1px solid rgba(0, 0, 0, 0.18);
  box-shadow: rgb(0 0 0 / 10%) 0px 10px 15px -3px,
    rgb(0 0 0 / 5%) 0px 4px 6px -2px;
  /* position: sticky;
  top: 0;
  z-index: 10000; */
}

.gallery-photo {
  border-radius: 5px;
  overflow: hidden;
  transition: all 300ms;
  cursor: pointer;
  /* box-shadow: 0px 0px 5px #00000038; */
  aspect-ratio: 3/2;
}

.gallery-photo:hover {
  opacity: 0.7;
}

.gallery-photo img {
  width: 100%;
}

.h-scroll {
  flex-wrap: nowrap !important;
  overflow: auto !important;
  padding-bottom: 0.7rem;
}

* {
  scrollbar-width: thin;
  scrollbar-color: rgb(1, 180, 188) rgba(255, 255, 255, 0);
}

*::-webkit-scrollbar {
  width: 16px;
}

*::-webkit-scrollbar-track {
  background: rgba(255, 255, 255, 0);
}

*::-webkit-scrollbar-thumb {
  background-color: rgb(1, 180, 188);
  border-radius: 10px;
  border: 3px solid #ffffff;
}

.contact-item {
  background: rgb(1, 180, 188);
  padding: 0.375rem;
  border-radius: 5px;
  display: flex;
  transition: all 300ms;
  cursor: pointer;
}

.contact-item:hover {
  opacity: 0.8;
}

.contact-image {
  width: 18%;
}

.contact-image img {
  width: 100%;
}

.contact-data {
  width: 82%;
  margin-top: auto;
  margin-bottom: auto;
  overflow: hidden;
}

.contact-data-title {
  font-family: "Roboto Condensed", sans-serif;
  font-size: 1.125rem;
  line-height: 1.25rem;
  font-weight: 700;
  color: #ffffff;
}

.contact-data-contact {
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 1rem;
  color: #ffffff;
  white-space: nowrap;
}

.header-inner {
  display: flex;
  justify-content: space-between;
  gap: 0.5rem;
  align-items: center;
}

.header-inner-left {
  width: 100%;
  overflow: hidden;
}

.header-inner-left-inner {
  display: flex;
  align-items: center;
}

.header-inner-left-icon {
  font-size: 1.75rem;
}

.header-inner-left-text {
  min-width: 80%;
}

.header-inner-left-prefix {
  color: #5f5f5f;
  font-size: 0.9375rem;
  font-weight: 400;
  line-height: 1.1875rem;
}

.header-inner-left-title {
  color: #5f5f5f;
  font-size: 1.5rem;
  line-height: 1.5rem;
  font-weight: 700;
  text-transform: uppercase;
  font-family: "Roboto Condensed", sans-serif;
}

.header-inner-right {
  display: flex;
  gap: 0.3rem;
  align-items: center;
}

.header-inner-right a {
  text-decoration: none;
}

.sort-button {
  background: rgb(1, 180, 188);
  /* background: #5f5f5f; */
  border-radius: 5px;
  border: none;
  padding: 0;
  transition: all 300ms;
  height: 2.5rem;
  display: flex;
  flex-grow: 1;
  position: relative;
  font-size: 1.25rem;
  line-height: 1.25rem;
  justify-content: center;
  align-items: center;
  color: #ffffff;
}

.sort-button:hover {
  opacity: 0.8;
}
.sort-button img {
  filter: invert(1);
  width: 1.25rem;
  display: flex;
  margin: auto;
}

.button-active {
  background: rgb(188, 9, 1);
}

.button-disabled {
  background: rgb(203 203 203);
  cursor: not-allowed !important;
}

.button-active:hover,
.button-disabled:hover {
  opacity: 1;
}

.back-button {
  background: rgb(188, 9, 1);
  border-radius: 5px;
  border: none;
  padding: 0;
  transition: all 300ms;
  width: 2.25rem;
  height: 2.25rem;
  display: flex;
}

.back-button:hover {
  opacity: 0.8;
}
.back-button img {
  filter: invert(1);
  width: 1.25rem;
  display: flex;
  margin: auto;
}

.bg-custom {
  background: #5f5f5f !important;
}

.bg-main {
  background: rgb(1, 180, 188);
  background: linear-gradient(
    90deg,
    rgba(1, 180, 188, 1) 40%,
    rgba(1, 180, 188, 0.7) 100%
  );
}

.action-buttons {
  display: flex;
  gap: 0.5rem;
  justify-content: center;
  margin-top: 1rem;
}

.badge-custom {
  transform: translate(-85%, -15%) !important;
  font-size: 0.75rem !important;
}

.contacts a {
  text-decoration: none;
}

.map-container {
  height: 75vh;
}

.col-overflow {
  overflow: hidden;
}

.text {
  white-space: pre-wrap;
}

.share-modal-body {
  flex-wrap: wrap;
  display: flex;
  justify-content: center;
  gap: 0.5rem;
}

.btn-primary {
  background-color: #01b4bc !important;
  border-color: #01b4bc !important;
}

.btn-danger {
  background-color: #bc0901 !important;
  border-color: #bc0901 !important;
}

.btn {
  transition: all 300ms !important;
}

.btn:hover {
  opacity: 0.8 !important;
}

.error-form-text {
  color: #bc0901;
  text-align: center;
  font-size: 0.875rem;
}

.comment-header {
  display: flex;
  gap: 0.5rem;
  align-items: center;
}

.comment-name {
  line-height: 1rem;
  font-weight: 600;
}

.comment-separator {
  border-left: 1px solid #000000;
  height: 1.125rem;
}

.comment-date {
  font-size: 0.6875rem;
  line-height: 0.6875rem;
  color: #bc0901;
  font-weight: 600;
}

.user-badge {
  background: #01b4bc;
  color: #ffffff;
  font-size: 1.25rem;
  line-height: 1.25rem;
  padding: 0.4375rem;
  border-radius: 50%;
  aspect-ratio: 1/1;
}

.comment-text {
  font-size: 0.9375rem;
}

.stars-holder {
  display: flex;
  justify-content: space-around;
}

.single-star {
  font-size: 1.5625rem;
  line-height: 1.5625rem;
  color: #01b4bc;
  cursor: pointer;
}

.news-list-item {
  overflow: hidden;
  cursor: pointer;
  border-radius: 5px 5px 0 0;
}

.news-item-image-frame {
  overflow: hidden;
  transition: all 300ms;
  aspect-ratio: 2/1;
}

.news-item-image-frame img {
  transition: all 300ms;
  width: 100%;
}

.news-item-image-frame img:hover {
  transform: scale(1.05);
}

.news-item-date {
  font-family: "Roboto Condensed", sans-serif;
  font-size: 0.75rem;
  line-height: 0.75rem;
  color: #212529;
  font-weight: 100;
}

.news-item-title {
  color: #212529;
  text-decoration: none;
  font-family: "Roboto Condensed", sans-serif;
  font-size: 1.25rem;
  line-height: 1.5rem;
  font-weight: 600;
  transition: all 300ms;
}

.news-item-title:hover {
  opacity: 0.8;
}

.news-item-intro {
  color: #000000;
}

.decoration {
  text-decoration: none;
}

.news-main-photo {
  position: relative;
  overflow: hidden;
  border-radius: 5px;
  transition: all 300ms;
  aspect-ratio: 3/2;
}

.news-main-photo img {
  transition: all 300ms;
  width: 100%;
}

.single-news-title {
  color: #5f5f5f;
  font-size: 1.75rem;
  line-height: 1.875rem;
  font-weight: 700;
}

.single-news-intro {
  white-space: pre-wrap;
}

.single-news-text {
  white-space: pre-wrap;
}

.single-news-intro a,
.single-news-intro a:hover,
.single-news-intro a:visited,
.single-news-text a,
.single-news-text a:hover,
.single-news-text a:visited {
  color: #01b4bc;
  text-decoration: none;
}

.single-news-date {
  font-family: "Roboto Condensed", sans-serif;
  font-size: 0.75rem;
  line-height: 0.75rem;
  color: #212529;
  font-weight: 400;
}
/* EVENT LIST */
.event-list-wrapper {
  display: flex;
  background: #01b4bc12;
  transition: all 300ms;
  justify-content: space-between;
  align-items: center;
  border-radius: 5px;
  overflow: hidden;
  padding-right: 0.5rem;
  gap: 0.5rem;
}
.event-list-wrapper-expired {
  display: flex;
  background: #f7f7f7;
  transition: all 300ms;
  justify-content: space-between;
  align-items: center;
  border-radius: 5px;
  overflow: hidden;
  padding-right: 0.5rem;
  gap: 0.5rem;
}
.event-list-wrapper:hover,
.event-list-wrapper-expired:hover {
  opacity: 0.8;
}
.event-list-content {
  display: flex;
  gap: 0.7rem;
  flex: auto;
}
.event-list-big-date {
  background: #01b4bc;
  font-family: "Roboto Condensed", sans-serif;
  font-weight: 600;
  color: #ffffff;
  display: flex;
  flex-direction: column;
  flex: 0 0 3.75rem;
  padding: 0.625rem;
  align-items: center;
}
.event-list-big-date-expired {
  background: #bcbcbc;
  font-family: "Roboto Condensed", sans-serif;
  font-weight: 600;
  color: #ffffff;
  display: flex;
  flex-direction: column;
  flex: 0 0 3.75rem;
  padding: 0.625rem;
  align-items: center;
}
.event-list-big-day {
  font-size: 2.25rem;
  line-height: 2.25rem;
}
.event-list-big-month {
  text-transform: uppercase;
  font-size: 1.3125rem;
  line-height: 1.3125rem;
}

.event-list-event-data {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 0.3rem;
}
.event-list-meta {
  font-family: "Roboto Condensed", sans-serif;
  font-size: 0.875rem;
  text-transform: uppercase;
  display: flex;
  color: #000000;
  line-height: 0.875rem;
  font-weight: 300;
}

.event-list-date {
  display: flex;
  align-items: center;
}
.event-list-date-expired {
  display: flex;
  text-decoration: line-through;
  align-items: center;
}

.event-list-category {
  color: #bc01b4;
  display: flex;
  align-items: center;
}
.event-list-separator {
  display: flex;
  align-items: center;
}
.event-list-title {
  font-family: "Roboto Condensed", sans-serif;
  font-size: 1.25rem;
  font-weight: 600;
  color: #212529;
  line-height: 1.375rem;
}

.event-list-status {
  display: flex;
  font-family: "Roboto Condensed", sans-serif;
  font-weight: 600;
  font-size: 0.875rem;
  text-transform: uppercase;
  white-space: nowrap;
}
.ongoing {
  color: #01b4bc;
}
.expired {
  color: #bc0901;
}

.s-event-list-meta {
  font-family: "Roboto Condensed", sans-serif;
  font-size: 0.75rem;
  text-transform: uppercase;
  display: flex;
  color: #000000;
  line-height: 0.75rem;
  font-weight: 300;
}

.s-event-list-title {
  font-family: "Roboto Condensed", sans-serif;

  font-weight: 600;
  color: #212529;
  line-height: 1.125rem;
}

.s-event-list-content {
  display: flex;
  gap: 0.5rem;
  flex: auto;
}

.s-event-list-big-day {
  font-size: 1.875rem;
  line-height: 1.875rem;
}

.s-event-list-big-date {
  background: #01b4bc;
  font-family: "Roboto Condensed", sans-serif;
  font-weight: 600;
  color: #ffffff;
  display: flex;
  flex-direction: column;
  flex: 0 0 3.25rem;
  padding: 0.5rem;
  align-items: center;
}

.s-event-list-big-date-expired {
  background: #bcbcbc;
  font-family: "Roboto Condensed", sans-serif;
  font-weight: 600;
  color: #ffffff;
  display: flex;
  flex-direction: column;
  flex: 0 0 3.25rem;
  padding: 0.5rem;
  align-items: center;
}

.s-event-list-big-month {
  text-transform: uppercase;
  font-size: 1.0625rem;
  line-height: 1.0625rem;
}

.s-event-list-status {
  display: flex;
  font-family: "Roboto Condensed", sans-serif;
  font-weight: 600;
  font-size: 0.6875rem;
  text-transform: uppercase;
  white-space: nowrap;
}

.single-events-date-time {
  display: flex;
  gap: 0.5rem;
  align-items: center;
}

.single-events-date-icon {
  color: #01b4bc;
}

.single-events-date-value {
  margin-right: 0.5rem;
  color: #212529;
  font-weight: 300;
  font-family: "Roboto Condensed", sans-serif;
  font-size: 0.75rem;
  line-height: 0.75rem;
}

.single-events-location-link {
  text-decoration: none;
  color: #01b4bc !important;
}

.crossline {
  text-decoration: line-through;
}

.navbar-brand {
  border-right: 1px solid rgba(255, 255, 255, 0.55);
  padding-right: 1rem;
}
.navbar-brand img {
  height: 37px;
}

.navbar-toggler {
  margin: 0 1rem 0 0 !important;
  border: 0 !important;
  padding: 0 !important;
  position: absolute;
  right: 0.625rem;
}

.navbar-toggler:focus {
  box-shadow: none !important;
}

.icon-primary {
  color: #01b4bc;
  transition: all 300ms;
}

.loading-more {
  display: flex;
  justify-content: space-around;
  font-size: 1.5rem;
}

.footer-copy {
  font-size: 0.8125rem;
  line-height: 0.8125rem;
  color: rgba(255, 255, 255, 0.7);
}

.footer-icons {
  display: flex !important;
  gap: 0.5rem;
  justify-content: flex-end;
  font-size: 1.125rem;
  line-height: 1.125rem;
  color: rgba(255, 255, 255, 0.6);
}

.footer-icons a {
  color: rgba(255, 255, 255, 0.6);
  transition: all 300ms;
}

.footer-icons a:hover {
  color: rgba(255, 255, 255, 1);
}

.footer-down {
  border-top: 1px solid rgba(255, 255, 255, 0.6);
  padding-top: 0.3125rem;
  align-items: center;
}

.footer-links a {
  font-size: 0.875rem;
  color: rgba(255, 255, 255, 0.6);
  transition: all 300ms;
}

.footer-links a:hover {
  color: rgba(255, 255, 255, 1);
}

.search-sidebar {
  display: flex;
  flex-direction: column;
}
.search-sidebar-height {
  height: -webkit-fill-available;
}

.search-sidebar-event-list-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 0.5rem;
  flex: 1 1;
  padding: 0.5rem;
}

.search-sidebar-event-list-title {
  color: #212529;
  text-decoration: none;
  font-family: "Roboto Condensed", sans-serif;

  line-height: 1rem;
  font-weight: 600;
  transition: all 300ms;
}

.search-sidebar-event-list-date-icon {
  color: #01b4bc;

  line-height: 1rem;
}

.read-more-button {
  background: rgb(1, 180, 188);
  color: #ffffff;
  border-radius: 5px;
  border: none;
  padding: 0px 0.75rem;
  transition: all 300ms;
  height: 2rem;
  display: flex;
  flex-grow: 1;
  position: relative;
  text-decoration: none;
  width: max-content;
  align-items: center;
  gap: 0.3rem;
  font-weight: 500;
  font-size: 0.9375rem;
  line-height: 0.9375rem;
}

.read-more-button:hover {
  opacity: 0.8;
}

.sticky-column {
  position: sticky;
  top: 0px;
}

.sidebar-group-list {
  list-style: none;
  margin-bottom: 0;
}

.sidebar-group-list a {
  text-decoration: none;
}

.sidebar-group-item {
  cursor: pointer;
  padding: 0.375rem 0.625rem;
  transition: all 300ms;
}

.sidebar-group-item-active {
  cursor: pointer;
  padding: 0.375rem 0.625rem;
  transition: all 300ms;
  background-color: #f7f7f7;
}

.sidebar-group-item:hover {
  background-color: #f7f7f7;
}

.sidebar-group-item-inner {
  display: flex;
  justify-content: space-between;
}

.sidebar-group-title {
  display: flex;
  gap: 0.5rem;
  align-items: center;
  color: #212529;
}

.sidebar-group-title img {
  width: 1.6875rem;
}

.sidebar-subgroup-list {
  list-style: none;
  display: none;
  padding: 0.3125rem 0 0 0;
}

.sidebar-subgroup-list-visible {
  list-style: none;
  display: block;
  padding: 0.3125rem 0 0 0;
}

.sidebar-subgroup-item {
  transition: all 300ms;
  gap: 0.3rem;
  display: flex;
  color: #212529;
  padding: 0.3125rem 0.3125rem 0.3125rem 0.5rem;
  font-size: 0.875rem;
  line-height: 0.875rem;
}

.sidebar-subgroup-item:hover {
  background: #e5e5e5;
}

.sidebar-subgroup-item-active {
  transition: all 300ms;
  gap: 0.3rem;
  display: flex;
  color: #ffffff;
  padding: 0.3125rem 0.3125rem 0.3125rem 0.5rem;
  font-size: 0.875rem;
  line-height: 0.875rem;
  background: #01b4bc;
}

.green {
  color: rgba(1, 180, 188, 1);
}

.subheader-wrap {
  border-bottom: 1px solid #01b4bc;
}

.subheader-wrap-link {
  border-bottom: 1px solid #01b4bc;
  transition: all 300ms;
  cursor: pointer;
}

.subheader-wrap-link:hover {
  background: #f7f7f7;
}

.subheader-title {
  color: #5f5f5f;
  font-size: 1.25rem;
  line-height: 1.25rem;
  font-weight: 700;
  font-family: "Roboto Condensed", sans-serif;
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

@media screen and (max-width: 661px) {
  html,
  body {
    font-size: 15px !important;
  }
  .search-promo-bg {
    background: rgb(1, 180, 188);
    background: linear-gradient(
      180deg,
      rgba(1, 180, 188, 0) 25%,
      rgba(1, 180, 188, 1) 25%,
      rgba(1, 180, 188, 1) 100%,
      rgba(1, 180, 188, 0) 100%
    );
  }
  .search-promo-wrap {
    justify-content: center;
    gap: 1rem;
  }
  .search-promo-right {
    color: #ffffff;
    display: flex;
    align-items: center;
    flex-direction: column;
  }
  .search-promo-right p {
    text-align: center;
  }
  .footer-down {
    border-top: 1px solid rgba(255, 255, 255, 0.6);
    padding-top: 0.3125rem;
    align-items: center;
    flex-direction: column;
  }
  .footer-copy {
    font-size: 0.75rem;
    line-height: 0.75rem;
    color: rgba(255, 255, 255, 0.7);
    text-align: center;
    margin-bottom: 0.625rem;
    width: 100% !important;
  }
  .footer-icons {
    justify-content: center;
    gap: 1rem;
  }
  .event-list-category,
  .event-list-separator,
  .event-list-status,
  .s-event-list-status {
    display: none !important;
  }
  .toast-container {
    width: 100% !important;
  }
  .toast {
    width: 100% !important;
  }
}

@media screen and (min-width: 662px) {
  .search-promo-right {
    color: #ffffff;
    justify-content: center;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
  }
  .navbar {
    padding-top: 0.875rem !important;
    padding-bottom: 0.875rem !important;
  }
}

@media screen and (min-width: 663px) and (max-width: 767px) {
  .search-promo-bg {
    background: rgb(1, 180, 188);
    background: linear-gradient(
      180deg,
      rgba(1, 180, 188, 0) 10%,
      rgba(1, 180, 188, 1) 10%,
      rgba(1, 180, 188, 1) 90%,
      rgba(1, 180, 188, 0) 90%
    );
  }
}

@media screen and (min-width: 768px) and (max-width: 991px) {
  .search-promo-bg {
    background: rgb(1, 180, 188);
    background: linear-gradient(
      180deg,
      rgba(1, 180, 188, 0) 15%,
      rgba(1, 180, 188, 1) 15%,
      rgba(1, 180, 188, 1) 85%,
      rgba(1, 180, 188, 0) 85%
    );
  }
}

@media screen and (max-width: 991px) {
  .navbar-brand {
    border: none !important;
    padding-right: 0 !important;
    margin: 0 auto !important;
  }
  .offcanvas-footer {
    display: flex !important;
    gap: 1rem;
    justify-content: center;
    font-size: 1.125rem;
    line-height: 1.125rem;
    color: rgba(255, 255, 255, 0.6);
    border-top: 1px solid rgba(255, 255, 255, 0.6);
    margin-top: 0.625rem;
    padding-top: 0.625rem;
  }
  .offcanvas-footer a {
    color: rgba(255, 255, 255, 0.6);
    transition: all 300ms;
  }

  .offcanvas-footer a:hover {
    color: rgba(255, 255, 255, 1);
  }
  .mob-adjust {
    display: flex;
    flex-direction: column-reverse;
  }
  .search-wrap {
    padding: 0.5rem 0 !important;
    opacity: 1;
  }
  .search-wrap-active {
    opacity: 1;
  }
  .open-search {
    display: none;
  }
  .addon-buttons {
    flex-direction: row !important;
    gap: 1rem !important;
  }
}
@media screen and (min-width: 992px) {
  .mob-adjust {
    display: flex;
    justify-content: space-between;
    flex: 0 0 100%;
  }
  .search-wrap {
    padding: 0.375rem 0 !important;
    transition: all 300ms;
    opacity: 0;
  }
  .search-wrap-active {
    padding: 0.375rem 0 !important;
    transition: all 300ms;
    opacity: 1;
  }
  .open-search {
    color: rgba(255, 255, 255, 0.55);
    background: none;
    border: none;
    transition: all 300ms;
    height: 2rem;
    width: 2rem;
    margin: auto 0.5rem auto 0;
    font-size: 1rem;
  }
  .open-search:hover {
    color: rgba(255, 255, 255, 0.75);
  }
}

@media screen and (min-width: 992px) and (max-width: 1199px) {
  .search-promo-bg {
    background: rgb(1, 180, 188);
    background: linear-gradient(
      180deg,
      rgba(1, 180, 188, 0) 20%,
      rgba(1, 180, 188, 1) 20%,
      rgba(1, 180, 188, 1) 80%,
      rgba(1, 180, 188, 0) 80%
    );
  }
}

@media screen and (min-width: 1200px) {
  .search-promo-bg {
    background: rgb(1, 180, 188);
    background: linear-gradient(
      180deg,
      rgba(1, 180, 188, 0) 30%,
      rgba(1, 180, 188, 1) 30%,
      rgba(1, 180, 188, 1) 70%,
      rgba(1, 180, 188, 0) 70%
    );
  }
}

.search-promo-left {
}

.search-promo-left img {
  width: 100%;
}

.search-promo-right h2 {
  font-weight: 600;
}

.search-promo-apps {
  display: flex;
  gap: 1rem;
}

.search-promo-bg-sidebar {
  background: rgb(1, 180, 188);
  background: linear-gradient(
    180deg,
    rgba(1, 180, 188, 0) 20%,
    rgba(1, 180, 188, 1) 20%,
    rgba(1, 180, 188, 1) 100%,
    rgba(1, 180, 188, 0) 100%
  );
}
.search-promo-wrap-sidebar {
  gap: 1rem;
  flex-direction: column;
  align-items: center;
}
.search-promo-right-sidebar {
  color: #ffffff;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.search-promo-right-sidebar h2 {
  font-weight: 600;
  text-align: center;
  font-size: 1.625rem;
  line-height: 1.75rem;
}
.search-promo-right-sidebar p {
  text-align: center;
  font-size: 0.9375rem;
  line-height: 1.25rem;
}
.search-promo-apps-sidebar {
  display: flex;
  gap: 0.5rem;
  flex-direction: column;
  align-items: center;
}

.sidebar-news {
  text-decoration: none;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.625rem !important;
  transition: all 300ms;
  border-radius: 5px;
  overflow: hidden;
}

.sidebar-news:hover {
  background: #f7f7f7;
}

.sidebar-news-image {
  display: flex;
  flex-basis: 30%;
  flex-grow: 0;
  flex-shrink: 0;
  border-radius: 5px;
  overflow: hidden;
  aspect-ratio: 3/2;
}

.sidebar-news-image img {
  width: 100%;
}

.sidebar-news-inner {
  display: flex;
  gap: 0.3rem;
  flex-direction: column;
  flex-basis: 70%;
}

.sidebar-news-date {
  display: flex;
  font-family: "Roboto Condensed", sans-serif;
  font-size: 0.75rem;
  line-height: 0.75rem;
  color: #000000;
}
.sidebar-news-title {
  display: flex;
  font-family: "Roboto Condensed", sans-serif;
  font-weight: 600;
  color: #000000;
  line-height: 1.125rem;
}
.classic-link {
  color: #01b4bc;
  text-decoration: none;
}
.classic-link:hover {
  color: #01b4bc;
  text-decoration: underline;
}
.policy-holder {
  overflow: scroll;
  height: 300px;
  font-size: 0.9375rem;
  line-height: 1.25rem;
}
.policy-holder a,
.policy-page a {
  color: #01b4bc;
  text-decoration: none;
}
.policy-holder a:hover,
.policy-page a:hover {
  color: #01b4bc;
  text-decoration: underline;
}
.policy-holder h1 {
  font-size: 1.5rem;
  font-weight: 600;
}
.policy-holder h2 {
  font-size: 1.125rem;
  font-weight: 600;
}
.form-label-addon {
  justify-content: space-between;
  display: flex;
  align-items: center;
}
.color-green {
  color: #01b4bc;
}
.color-red {
  color: #bc0901;
}
.scroll-snap-parent {
  scroll-snap-type: x mandatory;
}
.scroll-snap-child {
  scroll-snap-align: start;
}
.load-more-button {
  background: rgb(1, 180, 188);
  color: #ffffff;
  border-radius: 5px;
  border: none;
  padding: 0px 0.75rem;
  transition: all 300ms;
  height: 2rem;
  position: relative;
  text-decoration: none;
  width: max-content;
  align-items: center;
  font-weight: 500;
  font-size: 0.9375rem;
  line-height: 0.9375rem;
}

.load-more-button:hover {
  opacity: 0.8;
}

.events-sidebar-group-list {
  list-style: none;
  margin-bottom: 0;
}

.events-sidebar-group-list a {
  text-decoration: none;
}

.events-sidebar-group-item {
  cursor: pointer;
  padding: 0.375rem 0.625rem;
  transition: all 300ms;
}

.events-sidebar-group-item-active {
  cursor: pointer;
  padding: 0.375rem 0.625rem;
  transition: all 300ms;
  background-color: #01b4bc;
}

.events-sidebar-group-item:hover {
  background-color: #f7f7f7;
}

.events-sidebar-group-item-inner {
  display: flex;
  justify-content: space-between;
}

.events-sidebar-group-title {
  display: flex;
  gap: 0.5rem;
  align-items: center;
  color: #212529;
}

.events-sidebar-group-title-active {
  display: flex;
  gap: 0.5rem;
  align-items: center;
  color: #ffffff;
}
.offcanvas.offcanvas-top {
  height: fit-content !important;
  max-height: 50vh !important;
}
#navbar .btn-close {
  filter: invert(1);
  transition: all 300ms;
  margin: 0 !important;
  position: absolute;
  right: 0.9375rem;
}
#navbar .btn-close:focus {
  outline: none !important;
}
.offcanvas-header {
  border-bottom: 1px solid #01b4bc;
}
.lang-select {
  margin: auto 0;
}
.lang-select button {
  text-transform: uppercase !important;
  background: transparent !important;
  border: none !important;
  color: rgba(255, 255, 255, 0.55) !important;
  transition: all 300ms;
}
.lang-select button:hover {
  color: rgba(255, 255, 255, 0.75) !important;
  opacity: 1 !important;
}
.nav-link {
  font-weight: 500 !important;
}
.offcanvas-footer {
  display: none;
}
.scroll-top-wrap {
  position: fixed;
  right: 5%;
  bottom: 5%;
  z-index: 1;
  cursor: pointer;
  color: #ffffff;
  background: #01b4bc;
  border-radius: 5px;
  width: 2.5rem;
  height: 2.5rem;
  align-items: center;
  justify-content: center;
  transition: all 300ms;
}
.scroll-top-wrap:hover {
  opacity: 0.8 !important;
}
.scroll-top-inner {
  display: flex;
}
.cursor-pointer {
  cursor: pointer;
}
.header-input {
  padding: 0 0.5rem !important;
  border-radius: 0 !important;
  border-top: 1px solid #6b6b6b !important;
  border-left: none !important;
  border-right: none !important;
  border-bottom: 1px solid #6b6b6b !important;
  background: none !important;
  color: #ffffff !important;
  font-size: 1rem !important;
  line-height: 1rem !important;
}
.header-input:focus {
  outline: none !important;
  box-shadow: none !important;
}
nav .btn-primary {
  padding: 0.5rem 0.75rem !important;
  line-height: 1rem !important;
  border-radius: 5px;
}
.dropdown-item {
  font-size: 1rem !important;
  line-height: 1rem;
}
.dropdown-menu {
  position: absolute !important;
}
.search-location-wrap {
  text-decoration: none;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.625rem !important;
  transition: all 300ms;
  border-radius: 5px;
  overflow: hidden;
}
.search-location-wrap:hover {
  background: #f7f7f7;
}
.search-location-image {
  display: flex;
  flex: 0 0 36%;
  overflow: hidden;
  border-radius: 5px;
  aspect-ratio: 3/2;
}
.search-location-image img {
  width: 100%;
}
.search-location-content {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  color: #212529;
}
.search-location-name {
  text-decoration: none;
  font-family: "Roboto Condensed", sans-serif;
  font-size: 1.25rem;
  line-height: 1.5rem;
  font-weight: 600;
  transition: all 300ms;
}
.search-location-text {
  font-size: 0.875rem;
  line-height: 1rem;
}
.cookie-link {
  color: #01b4bc;
  text-decoration: none;
}
.cookie-link:hover {
  color: #01b4bc;
  text-decoration: underline;
}
.event-expired {
  display: flex;
  font-family: "Roboto Condensed", sans-serif;
  font-weight: 600;
  font-size: 0.875rem;
  text-transform: uppercase;
  white-space: nowrap;
  color: #bc0901;
}
.custom-modal-title {
  color: #5f5f5f;
  font-size: 1.375rem !important;
  line-height: 1.5rem !important;
  font-weight: 700 !important;
  font-family: "Roboto Condensed", sans-serif;
  display: flex;
  align-items: center;
  gap: 0.5rem;
}
